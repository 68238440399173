.release-modal {
  &__description {
    text-align: center;
    padding: 10px;
    width: 100%;
  }
  &__button-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}